* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: #E3AE81;
}

body {
  font-size: 100%;
}

.timer-container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  width: 100vw;
  height: 100v
}

.timer {
  background-color: ◎rgba(0, 0, 0, 0.5);
  display: flex;
  flex: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 7em;
  padding-top: 2em;
  width: 100%;
  height: 100%;

}

.timer div:first-child {
  text-align: center;
}

.timer-icon {
  font-size: 8em;
}

h2 {

  font-weight: 600;
  margin-bottom: 0.5em;

}

.timer div:first-child p {
  color: #f8f8f8;
}

.timer div:last-child {
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  padding: 3em;
  text-align: center;
}

section p:first-child,
.timer div:last-child span {
  font-size: 3em;

}

@media screen and (max-width: 480px) {
  timer div:last-child {
    margin-left: 2em;
    margin-right: 2em;

    padding: 3em 1em;

  }
}